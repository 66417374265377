<template>
  <vx-card style="padding-left: 10px">
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <!--<vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">-->
      <!--  <div class="w-full m-5">-->
      <!--    <label for="contactNumber">Contact Number</label>-->
      <!--    <vs-input-->
      <!--      data-vv-validate-on="blur"-->
      <!--      name="contactNumber"-->
      <!--      icon-no-border-->
      <!--      icon="icon icon-phone"-->
      <!--      icon-pack="feather"-->
      <!--      v-model="userDetail.contactNumber"-->
      <!--      class="w-full mb-8"-->
      <!--    />-->
      <!--  </div>-->
      <!--</vs-col>-->
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="firstName">First Name</label>
          <vs-input
            v-validate="'required|min:3|max:200'"
            data-vv-validate-on="blur"
            data-vv-as="first name"
            name="firstName"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="userDetail.firstName"
            class="w-full"
          />
          <span class="error">{{ errors.first("firstName") }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="lastName">Last Name</label>
          <vs-input
            v-validate="'required|min:3|max:200'"
            data-vv-validate-on="blur"
            name="lastName"
            data-vv-as="last name"
            icon-no-border
            icon="icon icon-user"
            icon-pack="feather"
            v-model="userDetail.lastName"
            class="w-full"
          />
          <span class="error">{{ errors.first("lastName") }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5">
          <label for="email">Email</label>
          <vs-input
            v-validate="'required|email|min:3'"
            data-vv-validate-on="blur"
            data-vv-delay="100"
            name="email"
            icon-no-border
            icon="icon icon-mail"
            icon-pack="feather"
            v-model="userDetail.email"
            class="w-full"
            :disabled="userDetail._id"
          />
          <span class="error">{{ errors.first("email") }}</span>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5" v-if="userDetail._id">
          <label for="disableProfile">Disable Profile</label>
          <vs-switch v-model="disableProfile">
            <span slot="on">Yes</span>
            <span slot="off">No</span>
          </vs-switch>
        </div>
      </vs-col>
      <!-- <vs-col> -->
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-5" v-if="userDetail._id">
          <vs-button
            color="success"
            type="flat"
            v-if="userDetail._id"
            @click="changePassword"
            >Change Password</vs-button
          >
        </div>
      </vs-col>
    </vs-row>

    <vs-row class="flex flex-wrap items-center p-6">
      <vs-button
        class="mr-6"
        color="danger"
        type="flat"
        @click="$router.replace('/admin/admin-user-management')"
        >Cancel</vs-button
      >
      <vs-button
        color="primary"
        v-if="!userDetail._id"
        @click="addNewUserDetail"
        >Save</vs-button
      >
      <vs-button color="primary" v-if="userDetail._id" @click="updateUserDetail"
        >Update</vs-button
      >
    </vs-row>
  </vx-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import { Validator } from "vee-validate";
import {adminMixin} from "../../../mixins/adminMixin"

export default {
  name: "UserDetails",
  mixins: [ adminMixin ],
  data: () => ({
    userDetail: {},
    disableProfile: false,
  }),
  methods: {
    ...mapActions("admin", [
      "fetchAdminDetails",
      "updateAdminDetails",
      "addNewAdmin",
    ]),
    getUserDetail(id) {
      let self = this;
      this.getAdminInfo(id).then((res) => {
        this.userDetail = res.adminInfo;
        // convert to firstname and lastname
        // let fullName = res.data.data.name.split(" ");
        // this.userDetail.firstName = fullName[0];
        // fullName.shift();
        // this.userDetail.lastName = fullName.join(" ");
        this.disableProfile =
          res.adminInfo.accountStatus !== "Active" ? true : false;
      });
    },
    updateUserDetail() {
      this.$validator.validateAll().then((success) => {
        if (!success) {
          return false;
        }
        this.userDetail.accountStatus = this.disableProfile ? 'Disabled' : 'Active';
        delete this.userDetail.__typename
        this.updateAdminInfo(this.userDetail).then((res) => {
          console.log(res)
          this.$vs.notify({
            title: "User Updated",
            text: res.message.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$router.push("/admin/admin-user-management");
        });
      });
    },
    addNewUserDetail() {
      this.$validator.validateAll().then((success) => {
        if (!success) {
          return false;
        }

        this.createNewAdmin(this.userDetail).then((res) => {
          console.log(res)
          this.$vs.notify({
            title: "User Added",
            text: res.message.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.$router.push("/admin/admin-user-management");
        }).catch(err => { 
          this.errors.add({
                field: 'email',
                msg: 'Email already exist.'
              })
                this.showDismissibleAlert = true
                this.message = "Email already exist."
                this.$vs.notify({
                  title: 'Error',
                  text: "Email already exist.",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })

        });
      });
    },
    changePassword() {
      this.$router.push(
        "/admin/admin-user-management/change-password/" + this.userDetail._id
      );
    },
  },
  created() {
    let userId = this.$route.params.id;
    if (userId) {
      this.getUserDetail(userId);
    } else {
      this.userDetail = {};
    }
  },
};
</script>
<style>
.ql-editor {
  min-height: 200px;
}
.error {
  color: red;
}
</style>