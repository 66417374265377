import { gql } from "apollo-boost"

export const adminMixin = {
    methods:{
        getAdmins(options){
            const getAdmins = gql`
            query adminList($options: PaginationInput!){
                adminList(options: $options){
                    admins{
                        _id
                        firstName
                        lastName
                        email
                        accountStatus
                    }
                    pagination{
                        total
                        page
                    }
                }
            }
            `;
            return this.$apollo.query({
                query: getAdmins,
                variables:{
                    options
                },
                fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.adminList
            })
        },
        createNewAdmin(adminData){
            const createNewAdmin = gql`
            mutation createNewAdmin($adminData: NewAdminInput!){
                createNewAdmin(request: $adminData){
                    message{
                        message
                        code
                    }
                }
            }
            `;
            return this.$apollo.mutate({
                mutation: createNewAdmin,
                variables:{
                    adminData
                },
                // fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.createNewAdmin
            }).catch( err =>{
                console.log(err)
                return {code: 400}
            })
        },
        updateAdminInfo(adminData){
            const updateAdmin = gql`
            mutation updateAdmin($adminData: UpdateAdminInput!){
                updateAdmin(request: $adminData){
                    message{
                        message
                        code
                    }
                }
            }
            `;
            return this.$apollo.mutate({
                mutation: updateAdmin,
                variables:{
                    adminData
                },
                // fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.updateAdmin
            }).catch( err =>{
                console.log(err)
                return {code: 400}
            })
        },
        getAdminInfo(_id){
            const getAdminInfo = gql`
            query getAdminInfo($_id: ID!){
                getAdminInfo(_id: $_id){
                    adminInfo{
                        _id
                        firstName
                        lastName
                        email
                        accountStatus
                    }
                }
            }
            `;
            return this.$apollo.query({
                query: getAdminInfo,
                variables:{
                    _id
                },
                fetchPolicy: 'network-only'

            }).then(response => {
                return response.data.getAdminInfo
            })
        },
        deleteAdmin(id){
            const deleteAdmin = gql`
            mutation deleteAdmin($id: ID!){
                deleteAdmin(_id: $id){
                    message{
                        message
                        code
                    }
                }
            }
            `;
            return this.$apollo.mutate({
                mutation: deleteAdmin,
                variables:{
                    id
                },
                // fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.deleteAdmin
            }).catch( err =>{
                console.log(err)
                return {code: 400}
            })
        },
        resetOtherAdminPassword(_id, password){
            const resetOtherAdminPassword = gql`
            mutation resetOtherAdminPassword($_id: ID! $password: String!){
                resetOtherAdminPassword(_id: $_id password: $password){
                    message{
                        message
                        code
                    }
                }
            }
            `;
            return this.$apollo.mutate({
                mutation: resetOtherAdminPassword,
                variables:{
                    _id, password
                },
                // fetchPolicy: 'network-only'
            }).then(response => {
                return response.data.resetOtherAdminPassword
            }).catch( err =>{
                console.log(err)
                return {code: 400}
            })
        },
    }
}
